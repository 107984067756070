import BaseService from "./BaseService";

class DistributorService extends BaseService {

    async getAll(req) {
        const response = await this.axios.post('/distributor/all', req);
        return await response.data;
    }

    async getDetails(req) {
        const response = await this.axios.post('/distributor/details', req);
        return await response.data;
    }

    async deleteDistributor(req) {
        const response = await this.axios.post('/distributor/delete', req);
        return response.data;
    }

    async blockDistributor(req) {
        const response = await this.axios.post('/distributor/block', req);
        return response.data;
    }

    async createDistributor(req) {
        const response = await this.axios.post('/distributor/create', req);
        return response.data;
    }

    async getPaymentProfile(req) {
        const response = await this.axios.post('/distributor/payment_profile', req);
        return response.data;
    }

    async getDistributorPercentages(req) {
        const response = await this.axios.post('/distributor/percentages', req);
        return response.data;
    }

    async updateDistributorPercentages(req) {
        const response = await this.axios.post('/distributor/percentages/update', req);
        return response.data;
    }


    async getDistributorMiscellaneous(req) {
        const response = await this.axios.post('/distributor/miscellaneous', req);
        return response.data;
    }

    async fecthDistributorPsxcmxbanxdis(req) {
        const response = await this.axios.post('/distributor/fetch_psxcmxbanxdis', req);
        return response.data;
    }

    async getDistributorQuota(req) {
        const response = await this.axios.post('/distributor/quota', req);
        return response.data;
    }

    async getAgencies(req) {
        const response = await this.axios.post('/distributor/agencies', req);
        return response.data;
    }


}

export default DistributorService
